import {useEffect, useState} from "react";
import {domain} from "../../../constants/Global";

function Subject(props) {

    const name = "Subject";
    const [linkedSubject] = useState(props.searchParams.Subject);
    const [loading, setLoading] = useState(false);
    const [subjects, setSubjects] = useState([]);

    function loadSubjects() {
        setLoading(true);
        fetch(
          domain + "/api-proxy/workday?_api_proxy_uri=INT172_Aims_Public_Schedule_Subjects%3Fformat%3Djson",
            {
                method: "GET",
            }
        )
            .then((response) => response.json())
            .then((data) => {
                setSubjects(data.Report_Entry);
                setLoading(false);
            })
            .catch((error) => console.log(error));
    }

    function santizeArray(subjects){
      let allSubjects = [];
      for(let subject of subjects){
        let nameAndID = subject["SISID-SubjectDescription"];
        let subjectInfoArray = nameAndID.split('-');
        let subjectID = subjectInfoArray[0];
        let subjectName = subjectInfoArray[1];
        let subjectObject = {'subjectName': subjectName, 'subjectID': subjectID};
        allSubjects.push(subjectObject);
      }
      return allSubjects.sort((a, b) => a.subjectName.localeCompare(b.subjectName));
    }

    function handleStateChange (e) {
      let coursesObj = props.coursesObj;
      props.setCoursesObj({
        ...coursesObj,
        Subject: e.target.value,
        default: false,
      });
    }

    function passSearchParams (e) {
      let coursesObj = props.coursesObj;
      props.setCoursesObj({
        ...coursesObj,
        Subject: linkedSubject
      });
    }

    useEffect(() => {loadSubjects();passSearchParams();}, []);
    let allSubjects = santizeArray(subjects);


    return (
        <>
            <label htmlFor="subjects" className="majorLabel">Subject:</label>
            <select name={name} id="subjects" size="5" className="selectBox" onChange={handleStateChange}>
                {loading ? (
                    <option>Loading List...</option>
                ) : (
                    allSubjects.map((subject) => {
                      if(subject.subjectID !== linkedSubject){
                        return [
                          <>
                            <option id={subject.subjectID} value={subject.subjectID + '-' + subject.subjectName}>{subject.subjectID} - {subject.subjectName}</option>
                          </>
                        ]
                      }
                      else{
                        return [
                          <>
                            <option id={subject.subjectID} value={subject.subjectID + '-' + subject.subjectName} selected>{subject.subjectID} - {subject.subjectName}</option>
                          </>
                        ]
                      }
                    })
                )}
            </select>
        </>
    )
}


export default Subject;
