function Head(){
    return(
        <>
            <link rel="canonical" href="https://www.aims.edu/" />
            <link rel="shortlink" href="https://www.aims.edu/" />
            <meta name="description" content="Study at Aims Community College. You belong here! Explore more than 200 degree and certificate programs, non-credit options, and transfer programs. Apply now." />
            <link rel="apple-touch-icon" sizes="180x180" href="https://ww.aims.edu/sites/default/files/favicons/apple-touch-icon.png"/>
            <link rel="icon" type="image/png" sizes="32x32" href="https://ww.aims.edu/sites/default/files/favicons/favicon-32x32.png"/>
            <link rel="icon" type="image/png" sizes="16x16" href="https://ww.aims.edu/sites/default/files/favicons/favicon-16x16.png"/>
            <link rel="manifest" href="https://ww.aims.edu/sites/default/files/favicons/site.webmanifest"/>
            <link rel="mask-icon" href="https://ww.aims.edu/sites/default/files/favicons/safari-pinned-tab.svg" color="#da1418"/>
            <meta name="msapplication-TileColor" content="#da1418"/>
            <meta name="theme-color" content="#ffffff"/>
            <meta name="favicon-generator" content="Drupal responsive_favicons + realfavicongenerator.net" />
            <meta name="Generator" content="Drupal 9 (https://www.drupal.org)" />
            <meta name="MobileOptimized" content="width" />
            <meta name="HandheldFriendly" content="true" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>Aims Community College | Degrees, Certificates, Non-credit | Greeley, CO</title>
            <link rel="stylesheet" media="all" href="https://ww.aims.edu/sites/default/files/css/css_dnIBKEyngcqZ0PTI6K5fLTbPqHgQ5W6yOhCg34vhCKU.css" />
            <link rel="stylesheet" media="all" href="https://ww.aims.edu/sites/default/files/css/css_NJEUBcP6IajJmxhAhUyi3OFPSGjB7g_mtz2oAinBYlI.css" />
            <link href="https://www.aims.edu/" rel="shortlink"/>
            <link href="https://www.aims.edu/sites/default/files/favicons/apple-touch-icon.png" rel="apple-touch-icon" sizes="180x180"/>
            <link href="https://www.aims.edu/sites/default/files/favicons/favicon-32x32.png" rel="icon" sizes="32x32" type="image/png"/>
            <link href="https://www.aims.edu/sites/default/files/favicons/favicon-16x16.png" rel="icon" sizes="16x16" type="image/png"/>
            <link href="https://www.aims.edu/sites/default/files/favicons/safari-pinned-tab.svg" rel="mask-icon"/>
            <link href="https://www.aims.edu/home" rel="revision"/>
            <link href="https://www.aims.edu/core/themes/stable/css/system/components/ajax-progress.module.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/core/themes/stable/css/system/components/align.module.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/core/themes/stable/css/system/components/autocomplete-loading.module.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/core/themes/stable/css/system/components/fieldgroup.module.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/core/themes/stable/css/system/components/container-inline.module.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/core/themes/stable/css/system/components/clearfix.module.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/core/themes/stable/css/system/components/details.module.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/core/themes/stable/css/system/components/hidden.module.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/core/themes/stable/css/system/components/item-list.module.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/core/themes/stable/css/system/components/js.module.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/core/themes/stable/css/system/components/nowrap.module.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/core/themes/stable/css/system/components/position-container.module.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/core/themes/stable/css/system/components/progress.module.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/core/themes/stable/css/system/components/reset-appearance.module.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/core/themes/stable/css/system/components/resize.module.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/core/themes/stable/css/system/components/sticky-header.module.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/core/themes/stable/css/system/components/system-status-counter.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/core/themes/stable/css/system/components/system-status-report-counters.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/core/themes/stable/css/system/components/system-status-report-general-info.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/core/themes/stable/css/system/components/tabledrag.module.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/core/themes/stable/css/system/components/tablesort.module.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/core/themes/stable/css/system/components/tree-child.module.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/core/themes/stable/css/views/views.module.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/core/themes/stable/css/media/oembed.formatter.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/modules/custom/acc_admin/css/additional-admin.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/modules/contrib/paragraphs/css/paragraphs.unpublished.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/themes/custom/aims/build/components/course-list/course-list.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/themes/custom/aims/build/components/ad-hoc-person/ad-hoc-person.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/themes/custom/aims/build/components/footer-logos/footer-logos.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/themes/custom/aims/build/components/global-search/global-search.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/themes/custom/aims/build/components/footer/footer.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/themes/custom/aims/build/components/profile-card/profile-card.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/themes/custom/aims/build/components/rich-text/rich-text.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/themes/custom/aims/build/components/heading/heading.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/themes/custom/aims/build/components/layout/layout.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/themes/custom/aims/build/components/micro-teaser/micro-teaser.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/themes/custom/aims/build/components/block-reference/block-reference.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/themes/custom/aims/build/components/video-spotlight/video-spotlight.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/themes/custom/aims/build/components/callout/callout.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/themes/custom/aims/build/components/feature-card/feature-card.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/themes/custom/aims/build/components/hero/hero.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/themes/custom/aims/build/components/mega-menu/mega-menu.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/themes/custom/aims/build/components/account-menu/account-menu.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/themes/custom/aims/build/components/header/header.css?qxr5wj" media="all" rel="stylesheet" />
            <link href="https://www.aims.edu/themes/custom/aims/build/components/notifications/notifications.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/themes/custom/aims/build/libraries/googlefonts/googlefonts.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/themes/custom/aims/libraries/icomoon/style.css?qxr5wj" media="all" rel="stylesheet"/>
            <link href="https://www.aims.edu/themes/custom/aims/build/libraries/global/global.css?qxr5wj" media="all" rel="stylesheet"/>
                <link href="https://www.aims.edu/themes/custom/aims/build/components/accordion/accordion.css" media="all" rel="stylesheet"/>
            <link rel="preconnect" href="https://fonts.googleapis.com"/>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>
            <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Roboto&display=swap" rel="stylesheet"/>
        </>
    );
}

export default Head;