import {useState, useEffect} from "react";
import {domain} from "../../../constants/Global";

function Location(props) {

  const [loading, setLoading] = useState(false);
  const [Location, SetLocations] = useState([]);
  const [linkedLocation] = useState(props.searchParams.Allowed_Locations);
  const name = "Allowed_Locations";

  function loadLocations() {
    setLoading(true);
    fetch(
      domain + "/api-proxy/workday?_api_proxy_uri=INT172_PMC_Locations%3Fformat%3Djson",
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        SetLocations(data.Report_Entry);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }

  function handleStateChange (e) {
    let coursesObj = props.coursesObj;
    props.setCoursesObj({
      ...coursesObj,
      Allowed_Locations: e.target.value,
      default: false,
    });
  }

  function passSearchParams (e) {
    let coursesObj = props.coursesObj;
    props.setCoursesObj({
      ...coursesObj,
      Allowed_Locations: linkedLocation
    });
  }

  useEffect(() => {loadLocations();passSearchParams();}, []);

  return (
    <>
      <span className="tooltip">
        <img className="icon" alt="Calendar Icon" src="https://www.aims.edu/themes/custom/aims/images/icons/question-mark.png"/>
        <span className="tooltiptext">If selecting a location, online courses will not be displayed.</span>
      </span>
      <label htmlFor="Location" className="majorLabel">Location:</label>
      {Location.map((location_name) => {
        if(location_name.referenceID !== linkedLocation){
          return [
            <>
              <input type="radio" id={location_name.referenceID} name={name} value={location_name.referenceID} className="radio" onChange={handleStateChange}/>
              <label htmlFor={location_name.referenceID}>{location_name.locationName}</label>
              <br/>
            </>
          ]
        }
        else{
          return [
            <>
              <input type="radio" id={location_name.referenceID} name={name} value={location_name.referenceID} className="radio" checked onChange={handleStateChange}/>
              <label htmlFor={location_name.referenceID}>{location_name.locationName}</label>
              <br/>
            </>
          ]
        }
      })}
    </>
  );
}

export default Location;
