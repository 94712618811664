function Header(){
    return(
        <>
            <header className="header">
                <div className="header__utilities l-offset">
                    <div className="header__utilities-wrapper l-constrained">
                        <div className="c-notification-button c-notification-button--desktop js-notification-button">
                            <button aria-controls="acc-notifications">
                                <span className="c-notification-button__open"><span className="icon-alert"></span>View Alerts</span>
                                <span className="c-notification-button__close"><span className="icon-x"></span>Close Alerts</span>
                            </button>
                        </div>
                        <button className="toggle-language" translate="no" data-language="es">En Español</button>
                        <nav aria-labelledby="block-utilities-menu" id="block-utilities">
                            <h2 className="visually-hidden" id="block-utilities-menu">Utilities</h2>
                            <ul className="menu menu--utilities" data-depth="0">
                                <li className="menu__item">
                                    <a href="/request-information" className="menu__link"
                                       data-drupal-link-system-path="node/20989">Request Info</a>
                                </li>
                            </ul>
                        </nav>
                        <nav aria-labelledby="block-useraccountmenu-menu" id="block-useraccountmenu">
                            <h2 className="visually-hidden" id="block-useraccountmenu-menu">User account menu</h2>
                            <div className="c-account-menu js-account-menu">
                                <button className="c-account-menu__toggle js-account-menu__toggle"
                                        id="toggle-accountanon443245007" aria-expanded="false"
                                        aria-controls="panel-accountanon443245007"><span className="icon-myaims"></span>myAims
                                    - Log in
                                </button>
                                <div className="c-account-menu__panel js-account-menu__panel"
                                     id="panel-accountanon443245007" aria-labelledby="toggle-accountanon443245007">
                                    <ul className="menu menu--account" data-depth="0">
                                        <li className="menu__item">
                                            <a href="/saml/login">Log in</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                        <div id="block-globalsearchblock">
                            <div
                                className="c-global-search"
                            >
                                <form className="c-global-search__form" action="/search">
                                    <label htmlFor="acc_search-desktop" className="visually-hidden">Search</label>
                                    <input className="c-global-search__input" type="text" id="acc_search-desktop"
                                           name="search" placeholder="Search the website"></input>
                                        <input className="c-global-search__submit" type="submit" value="Submit"></input>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header__main">
                    <div className="header__inner l-constrained l-offset">
                        <div className="header__logo">
                            <a href="/" title="Home" rel="home"
                               className="logo"
                            >

                            </a>
                        </div>
                        <div className="c-notification-button c-notification-button--mobile js-notification-button">
                            <button aria-controls="acc-notifications">
                                <span className="c-notification-button__open"><span className="icon-alert"></span>View Alerts</span>
                                <span className="c-notification-button__close"><span className="icon-x"></span>Close Alerts</span>
                            </button>
                        </div>
                        <input type="checkbox" id="nav-trigger" className="nav-trigger js-mobile-nav-trigger"
                               value="expanded"/>
                        <label htmlFor="nav-trigger">
                            <span className="visually-hidden">Open Menu</span>
                            <svg className="nav-trigger__icon nav-trigger__icon--expand"
                                 xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                                <path d="M20 31h24v2H20zM20 25h24v2H20zM20 37h24v2H20z"/>
                            </svg>
                            <svg className="nav-trigger__icon nav-trigger__icon--collapse"
                                 xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                                <path
                                    d="M22.8076 39.7773l16.97056275-16.97056275 1.41421356 1.41421356-16.97056275 16.97056275z"/>
                                <path
                                    d="M24.2217 22.8086l16.97056275 16.97056275-1.41421356 1.41421356-16.97056275-16.97056275z"/>
                            </svg>
                        </label>
                        <div className="header__site-navigation">
                            <div className="header__menu-main">
                                <nav aria-labelledby="block-aims-main-menu-menu" id="block-aims-main-menu">
                                    <h2 className="visually-hidden" id="block-aims-main-menu-menu">Main navigation</h2>
                                    <div className="c-mega-menu c-mega-menu--no-panel-open js-mega-menu">
                                        <ul data-region="header" className="menu menu--main" data-depth="0">
                                            <li className="menu__item menu__item--expanded">
                                                <div className="c-mega-menu__top-item">
                                                    <a href="/programs" className="menu__link"
                                                       data-drupal-link-system-path="node/6895">Programs</a>
                                                    <button className="c-mega-menu__toggle js-mega-menu__toggle"
                                                            id="mmtoggle-0-1" aria-controls="mmpanel-0-1"
                                                            aria-expanded="false"><span className="visually-hidden">Expand Section</span>
                                                    </button>
                                                </div>
                                                <div className="c-mega-menu__panel js-mega-menu__panel" id="mmpanel-0-1"
                                                     aria-labelledby="mmtoggle-0-1" role="navigation">
                                                    <ul data-region="header" className="menu menu--main" data-depth="1">
                                                        <li className="menu__item menu__item--expanded">
                                                            <span
                                                                className="menu__link">Degrees &amp; Certificates</span>
                                                            <ul data-region="header" className="menu menu--main"
                                                                data-depth="2">
                                                                <li className="menu__item">
                                                                    <a href="/degrees-certificates?program_type%5B16218%5D=16218&amp;program_type%5B110193%5D=110193"
                                                                       className="menu__link"
                                                                       data-drupal-link-query="{&quot;program_type&quot;:{&quot;16218&quot;:&quot;16218&quot;,&quot;110193&quot;:&quot;110193&quot;}}"
                                                                       data-drupal-link-system-path="node/1801">Degrees</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="/degrees-certificates?program_type%5B16216%5D=16216"
                                                                       className="menu__link"
                                                                       data-drupal-link-query="{&quot;program_type&quot;:{&quot;16216&quot;:&quot;16216&quot;}}"
                                                                       data-drupal-link-system-path="node/1801">Certificates</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="/degrees-certificates"
                                                                       className="menu__link"
                                                                       data-drupal-link-system-path="node/1801">Search
                                                                        all credit programs</a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li className="menu__item menu__item--expanded">
                                                            <span
                                                                className="menu__link">Training &amp; Enrichment</span>
                                                            <ul data-region="header" className="menu menu--main"
                                                                data-depth="2">
                                                                <li className="menu__item">
                                                                    <a href="/programs/continuing-education"
                                                                       className="menu__link"
                                                                       data-drupal-link-system-path="node/20199">Continuing
                                                                        Education</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="/programs/commercial-drivers-license"
                                                                       className="menu__link"
                                                                       data-drupal-link-system-path="node/10184">Commercial
                                                                        Driver License (CDL)</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="/departments/workforce-development"
                                                                       className="menu__link"
                                                                       data-drupal-link-system-path="node/7781">Workforce
                                                                        Development</a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li className="menu__item menu__item--expanded">
                                                            <span className="menu__link">Aims Programs</span>
                                                            <ul data-region="header" className="menu menu--main"
                                                                data-depth="2">
                                                                <li className="menu__item">
                                                                    <a href="/transfer-programs" className="menu__link"
                                                                       data-drupal-link-system-path="node/10185">Transfer
                                                                        Programs</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="/programs/high-school-programs"
                                                                       className="menu__link"
                                                                       data-drupal-link-system-path="node/10186">High
                                                                        School Programs</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="/programs/ged-esl" className="menu__link"
                                                                       data-drupal-link-system-path="node/10243">GED and
                                                                        ESL</a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li className="menu__item menu__item--expanded">
                                                <div className="c-mega-menu__top-item">
                                                    <a href="/admissions" className="menu__link"
                                                       data-drupal-link-system-path="node/10725">Admissions</a>
                                                    <button className="c-mega-menu__toggle js-mega-menu__toggle"
                                                            id="mmtoggle-0-2" aria-controls="mmpanel-0-2"
                                                            aria-expanded="false"><span className="visually-hidden">Expand Section</span>
                                                    </button>
                                                </div>
                                                <div className="c-mega-menu__panel js-mega-menu__panel" id="mmpanel-0-2"
                                                     aria-labelledby="mmtoggle-0-2" role="navigation">
                                                    <ul data-region="header" className="menu menu--main" data-depth="1">
                                                        <li className="menu__item menu__item--expanded">
                                                            <span className="menu__link">Get Started</span>
                                                            <ul data-region="header" className="menu menu--main"
                                                                data-depth="2">
                                                                <li className="menu__item">
                                                                    <a href="/admissions/apply" className="menu__link"
                                                                       data-drupal-link-system-path="node/10244">Apply
                                                                        Now</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="/admissions/enrollment-steps"
                                                                       className="menu__link"
                                                                       data-drupal-link-system-path="node/10246">Enrollment
                                                                        Steps</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="/admissions/visit-campus"
                                                                       className="menu__link"
                                                                       data-drupal-link-system-path="node/10692">Visit
                                                                        Campus</a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li className="menu__item menu__item--expanded">
                                                            <span className="menu__link">Cost &amp; Aid</span>
                                                            <ul data-region="header" className="menu menu--main"
                                                                data-depth="2">
                                                                <li className="menu__item">
                                                                    <a href="/departments/cashiers-office/tuition-fees"
                                                                       className="menu__link"
                                                                       data-drupal-link-system-path="node/7073">Tuition
                                                                        and Fees</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="/departments/financial-aid"
                                                                       className="menu__link"
                                                                       data-drupal-link-system-path="node/6224">Financial
                                                                        Aid</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="https://www.aims.edu/departments/financial-aid/types-financial-aid#scholarships"
                                                                       className="menu__link">Scholarships</a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li className="menu__item menu__item--expanded">
                                                            <span className="menu__link">Get Help</span>
                                                            <ul data-region="header" className="menu menu--main"
                                                                data-depth="2">
                                                                <li className="menu__item">
                                                                    <a href="/departments/navigators"
                                                                       className="menu__link"
                                                                       data-drupal-link-system-path="node/7773">Ask a
                                                                        Navigator</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="/departments/pathway-advising"
                                                                       className="menu__link"
                                                                       data-drupal-link-system-path="node/6155">Pathway
                                                                        Advising</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="/request-information"
                                                                       className="menu__link"
                                                                       data-drupal-link-system-path="node/20989">Request
                                                                        Information</a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li className="menu__item menu__item--expanded">
                                                <div className="c-mega-menu__top-item">
                                                    <a href="/student-life" className="menu__link"
                                                       data-drupal-link-system-path="node/10261">Student Life</a>
                                                    <button className="c-mega-menu__toggle js-mega-menu__toggle"
                                                            id="mmtoggle-0-3" aria-controls="mmpanel-0-3"
                                                            aria-expanded="false"><span className="visually-hidden">Expand Section</span>
                                                    </button>
                                                </div>
                                                <div className="c-mega-menu__panel js-mega-menu__panel" id="mmpanel-0-3"
                                                     aria-labelledby="mmtoggle-0-3" role="navigation">
                                                    <ul data-region="header" className="menu menu--main" data-depth="1">
                                                        <li className="menu__item menu__item--expanded">
                                                            <span className="menu__link">Get Involved</span>
                                                            <ul data-region="header" className="menu menu--main"
                                                                data-depth="2">
                                                                <li className="menu__item">
                                                                    <a href="/student-life/clubs-organizations"
                                                                       className="menu__link"
                                                                       data-drupal-link-system-path="node/10189">Clubs
                                                                        and Organizations</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="/departments/sail" className="menu__link"
                                                                       data-drupal-link-system-path="node/7776">Student
                                                                        Activities, Inclusion and Leadership</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="/departments/center-diversity-and-inclusion"
                                                                       className="menu__link"
                                                                       data-drupal-link-system-path="node/7784">Center
                                                                        for Diversity and Inclusion</a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li className="menu__item menu__item--expanded">
                                                            <span className="menu__link">Resources</span>
                                                            <ul data-region="header" className="menu menu--main"
                                                                data-depth="2">
                                                                <li className="menu__item">
                                                                    <a href="/student-life/student-services"
                                                                       className="menu__link"
                                                                       data-drupal-link-system-path="node/6154">Student
                                                                        Services</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="/departments/campus-safety-security"
                                                                       className="menu__link"
                                                                       data-drupal-link-system-path="node/6739">Campus
                                                                        Safety and Security</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="https://live-aims-cc.pantheonsite.io/departments/center-diversity-and-inclusion/veterans-engagement"
                                                                       className="menu__link">Veterans Engagement</a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li className="menu__item menu__item--expanded">
                                                            <span className="menu__link">Quick Links</span>
                                                            <ul data-region="header" className="menu menu--main"
                                                                data-depth="2">
                                                                <li className="menu__item">
                                                                    <a href="https://online.aims.edu/d2l/home"
                                                                       className="menu__link">D2L Login</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="/resource-library/class-schedule"
                                                                       className="menu__link"
                                                                       data-drupal-link-system-path="node/10124">Class
                                                                        Schedule</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="https://www.aims.edu/myaims-student/grades-academic-records"
                                                                       className="menu__link">Grades</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="/resource-library/academic-calendars"
                                                                       className="menu__link"
                                                                       data-drupal-link-system-path="node/10171">Academic
                                                                        Calendar</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="https://aims.bncollege.com/shop/aims/home"
                                                                       className="menu__link">Bookstore</a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li className="menu__item menu__item--expanded">
                                                <div className="c-mega-menu__top-item">
                                                    <a href="/news-events" className="menu__link"
                                                       data-drupal-link-system-path="node/10824">News &amp; Events</a>
                                                    <button className="c-mega-menu__toggle js-mega-menu__toggle"
                                                            id="mmtoggle-0-4" aria-controls="mmpanel-0-4"
                                                            aria-expanded="false"><span className="visually-hidden">Expand Section</span>
                                                    </button>
                                                </div>
                                                <div className="c-mega-menu__panel js-mega-menu__panel" id="mmpanel-0-4"
                                                     aria-labelledby="mmtoggle-0-4" role="navigation">
                                                    <ul data-region="header" className="menu menu--main" data-depth="1">
                                                        <li className="menu__item menu__item--expanded">
                                                            <span className="menu__link">News</span>
                                                            <ul data-region="header" className="menu menu--main"
                                                                data-depth="2">
                                                                <li className="menu__item">
                                                                    <a href="/news" className="menu__link"
                                                                       data-drupal-link-system-path="node/10192">Latest
                                                                        Posts</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="/news?category%5B35544%5D=35544"
                                                                       className="menu__link"
                                                                       data-drupal-link-query="{&quot;category&quot;:{&quot;35544&quot;:&quot;35544&quot;}}"
                                                                       data-drupal-link-system-path="node/10192">Student
                                                                        Spotlight</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="/news?category%5B34905%5D=34905"
                                                                       className="menu__link"
                                                                       data-drupal-link-query="{&quot;category&quot;:{&quot;34905&quot;:&quot;34905&quot;}}"
                                                                       data-drupal-link-system-path="node/10192">Alumni
                                                                        Spotlight</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="/news?category%5B35511%5D=35511"
                                                                       className="menu__link"
                                                                       data-drupal-link-query="{&quot;category&quot;:{&quot;35511&quot;:&quot;35511&quot;}}"
                                                                       data-drupal-link-system-path="node/10192">Faculty
                                                                        Spotlight</a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li className="menu__item menu__item--expanded">
                                                            <span className="menu__link">Events</span>
                                                            <ul data-region="header" className="menu menu--main"
                                                                data-depth="2">
                                                                <li className="menu__item">
                                                                    <a href="https://events.aims.edu/calendar/week?order=date&amp;experience=&amp;event_types%5B%5D=33165552221205"
                                                                       className="menu__link">Student Events</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="https://events.aims.edu/calendar/week?order=date&amp;experience=&amp;event_types%5B%5D=33165552228376"
                                                                       className="menu__link">Community Events</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="https://events.aims.edu"
                                                                       className="menu__link">See all events...</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="/departments/event-management"
                                                                       className="menu__link"
                                                                       title="The Aims Events Management team serves both internal and external communities through events and activities that are life events, instructional, social and recreational in nature."
                                                                       data-drupal-link-system-path="node/17948">Book an
                                                                        Event</a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li className="menu__item menu__item--expanded">
                                                <div className="c-mega-menu__top-item">
                                                    <a href="/about" className="menu__link"
                                                       data-drupal-link-system-path="node/10200">About</a>
                                                    <button className="c-mega-menu__toggle js-mega-menu__toggle"
                                                            id="mmtoggle-0-5" aria-controls="mmpanel-0-5"
                                                            aria-expanded="false"><span className="visually-hidden">Expand Section</span>
                                                    </button>
                                                </div>
                                                <div className="c-mega-menu__panel js-mega-menu__panel" id="mmpanel-0-5"
                                                     aria-labelledby="mmtoggle-0-5" role="navigation">
                                                    <ul data-region="header" className="menu menu--main" data-depth="1">
                                                        <li className="menu__item menu__item--expanded">
                                                            <span className="menu__link">Who We Are</span>
                                                            <ul data-region="header" className="menu menu--main"
                                                                data-depth="2">
                                                                <li className="menu__item">
                                                                    <a href="/about/our-history" className="menu__link"
                                                                       data-drupal-link-system-path="node/10252">Our
                                                                        History</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="/about/mission-vision"
                                                                       className="menu__link"
                                                                       data-drupal-link-system-path="node/10253">Mission
                                                                        and Vision</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="/about/accreditation"
                                                                       className="menu__link"
                                                                       data-drupal-link-system-path="node/10254">Accreditation</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="/departments/foundation"
                                                                       className="menu__link"
                                                                       data-drupal-link-system-path="node/7767">Aims
                                                                        Foundation</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="/resource-library/annual-reports"
                                                                       className="menu__link"
                                                                       data-drupal-link-system-path="node/9990">Annual
                                                                        Reports</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="/departments/presidents-office"
                                                                       className="menu__link"
                                                                       data-drupal-link-system-path="node/7574">Office
                                                                        of the President</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="/departments/presidents-office/board-of-trustees"
                                                                       className="menu__link"
                                                                       data-drupal-link-system-path="node/10013">Board
                                                                        of Trustees</a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li className="menu__item menu__item--expanded">
                                                            <span className="menu__link">Directories</span>
                                                            <ul data-region="header" className="menu menu--main"
                                                                data-depth="2">
                                                                <li className="menu__item">
                                                                    <a href="/departments" className="menu__link"
                                                                       data-drupal-link-system-path="node/10256">All
                                                                        Departments</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="/employee-directory" className="menu__link"
                                                                       data-drupal-link-system-path="node/7549">Staff
                                                                        Directory</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="/resource-library" className="menu__link"
                                                                       data-drupal-link-system-path="node/7694">Search
                                                                        Documents</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="https://catalog.aims.edu"
                                                                       className="menu__link">Search Catalog</a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                        <li className="menu__item menu__item--expanded">
                                                            <span className="menu__link">Connect with Aims</span>
                                                            <ul data-region="header" className="menu menu--main"
                                                                data-depth="2">
                                                                <li className="menu__item">
                                                                    <a href="/about/contact-us" className="menu__link"
                                                                       data-drupal-link-system-path="node/10257">Contact
                                                                        Us</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="/locations" className="menu__link"
                                                                       data-drupal-link-system-path="node/10156">Locations</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="/admissions/visit-campus"
                                                                       className="menu__link"
                                                                       data-drupal-link-system-path="node/10692">Visit
                                                                        Campus</a>
                                                                </li>
                                                                <li className="menu__item">
                                                                    <a href="/departments/employer-community-engagement-services"
                                                                       className="menu__link"
                                                                       data-drupal-link-system-path="node/18363">Employer
                                                                        Services</a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                                <div id="block-applybutton">
                  <span className="c-apply-button">
                  <a href="/apply" className="u-button u-button--primary">Apply</a>
                  </span>
                                </div>
                            </div>
                            <div className="header__mobile-utilities">
                                <div id="block-globalsearchblock-mobile">
                                    <div
                                        className="c-global-search"
                                    >
                                        <form className="c-global-search__form" action="/search">
                                            <label htmlFor="acc_search-mobile"
                                                   className="visually-hidden">Search</label>
                                            <input className="c-global-search__input" type="text" id="acc_search-mobile"
                                                   name="search" placeholder="Search the website"></input>
                                            <input className="c-global-search__submit" type="submit" value="Submit"></input>
                                        </form>
                                    </div>
                                </div>
                                <nav aria-labelledby="block-useraccountmenu-mobile-menu"
                                     id="block-useraccountmenu-mobile">
                                    <h2 className="visually-hidden" id="block-useraccountmenu-mobile-menu">User account
                                        menu - Mobile</h2>
                                    <div className="c-account-menu js-account-menu">
                                        <button className="c-account-menu__toggle js-account-menu__toggle"
                                                id="toggle-accountanon1170834050" aria-expanded="false"
                                                aria-controls="panel-accountanon1170834050"><span
                                            className="icon-myaims"></span>myAims - Log in
                                        </button>
                                        <div className="c-account-menu__panel js-account-menu__panel"
                                             id="panel-accountanon1170834050"
                                             aria-labelledby="toggle-accountanon1170834050">
                                            <ul className="menu menu--account" data-depth="0">
                                                <li className="menu__item">
                                                    <a href="/saml/login">Log in</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </nav>
                                <nav aria-labelledby="block-utilities-mobile-menu" id="block-utilities-mobile">
                                    <h2 className="visually-hidden" id="block-utilities-mobile-menu">Utilities
                                        Mobile</h2>
                                    <ul className="menu menu--utilities" data-depth="0">
                                        <li className="menu__item">
                                            <a href="/request-information" className="menu__link"
                                               data-drupal-link-system-path="node/20989">Request Info</a>
                                        </li>
                                    </ul>
                                </nav>
                                <button className="toggle-language" translate="no" data-language="es">En Español
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default Header;