import { Route, Routes, useSearchParams } from "react-router-dom";
import Courses from "./components/courses/Courses";
import Filters from "./components/filters/Filters";
import Header from "./components/site_wrapper/Header";
import Head from "./components/site_wrapper/Head";
import './App.css';
import { useState } from 'react';

function App() {

  const [searchParams] = useSearchParams();

  const [coursesObj, setCoursesObj] = useState({
    default: true,
    SISTermID: searchParams.get('SISTermID') ? searchParams.get('SISTermID') : '',
    Online_Only: searchParams.get('Online_Only') ? searchParams.get('Online_Only') : '',
    Subject: searchParams.get('Subject') ? searchParams.get('Subject') : '',
    Allowed_Locations: searchParams.get('Allowed_Locations') ? searchParams.get('Allowed_Locations') : '',
    CourseNumber: searchParams.get('CourseNumber') ? searchParams.get('CourseNumber') : '',
    SectionStatus: searchParams.get('SectionStatus') ? searchParams.get('SectionStatus') : '',
    StartAfter: searchParams.get('StartAfter') ? searchParams.get('StartAfter') : '',
    EndBy: searchParams.get('EndBy') ? searchParams.get('EndBy') : '',
    IsMonday: searchParams.get('IsMonday') ? searchParams.get('IsMonday') : '',
    IsTuesday: searchParams.get('IsTuesday') ? searchParams.get('IsTuesday') : '',
    IsWednesday: searchParams.get('IsWednesday') ? searchParams.get('IsWednesday') : '',
    IsThursday: searchParams.get('IsThursday') ? searchParams.get('IsThursday') : '',
    IsFriday: searchParams.get('IsFriday') ? searchParams.get('IsFriday') : '',
    IsSaturday: searchParams.get('IsSaturday') ? searchParams.get('IsSaturday') : '',
    IsSunday: searchParams.get('IsSunday') ? searchParams.get('IsSunday') : '',
    StartDate: searchParams.get('StartDate') ? searchParams.get('StartDate') : '',
    EndDate: searchParams.get('EndDate') ? searchParams.get('EndDate') : '',
    Title: searchParams.get('Title') ? searchParams.get('Title') : '',
    Instructor: searchParams.get('Instructor') ? searchParams.get('Instructor') : '',
  });

  const [paramsObj] = useState({
    SISTermID: searchParams.get('SISTermID') ? searchParams.get('SISTermID') : '',
    Online_Only: searchParams.get('Online_Only') ? searchParams.get('Online_Only') : '',
    Subject: searchParams.get('Subject') ? searchParams.get('Subject') : '',
    Allowed_Locations: searchParams.get('Allowed_Locations') ? searchParams.get('Allowed_Locations') : '',
    CourseNumber: searchParams.get('CourseNumber') ? searchParams.get('CourseNumber') : '',
    SectionStatus: searchParams.get('SectionStatus') ? searchParams.get('SectionStatus') : '',
    StartAfter: searchParams.get('StartAfter') ? searchParams.get('StartAfter') : '',
    EndBy: searchParams.get('EndBy') ? searchParams.get('EndBy') : '',
    IsMonday: searchParams.get('IsMonday') ? searchParams.get('IsMonday') : '',
    IsTuesday: searchParams.get('IsTuesday') ? searchParams.get('IsTuesday') : '',
    IsWednesday: searchParams.get('IsWednesday') ? searchParams.get('IsWednesday') : '',
    IsThursday: searchParams.get('IsThursday') ? searchParams.get('IsThursday') : '',
    IsFriday: searchParams.get('IsFriday') ? searchParams.get('IsFriday') : '',
    IsSaturday: searchParams.get('IsSaturday') ? searchParams.get('IsSaturday') : '',
    IsSunday: searchParams.get('IsSunday') ? searchParams.get('IsSunday') : '',
    StartDate: searchParams.get('StartDate') ? searchParams.get('StartDate') : '',
    EndDate: searchParams.get('EndDate') ? searchParams.get('EndDate') : '',
    Title: searchParams.get('Title') ? searchParams.get('Title') : '',
    Instructor: searchParams.get('Instructor') ? searchParams.get('Instructor') : '',
  });



  return (
    <div>
      <Head />
        <div class="l-layout-container">
          <Header />
            <div class="paragraph paragraph--type--layout paragraph--view-mode--default c-layout">
                <div class="l-section--normal t-normal t-gutter-normal t- l-section">
                    <div class="l-section__inner">
                        <div class="l-section__col l-section__col--">
                          <h1>Public Class Schedule</h1>
                          <p>For best results using the class schedule, select as many filters as possible for your search.
                            A list of known issues and enhancements in development can be <a href="/class-schedule/known-issues">viewed here.</a></p>
                              <div id="searchResults"></div>
                              <Filters searchParams={paramsObj} coursesObj={coursesObj} setCoursesObj={setCoursesObj}/>
                              <Routes>
                                <Route path="/" element={<Courses searchParams={paramsObj} coursesData={coursesObj} />} />
                                <Route path="/search" element={<Courses searchParams={paramsObj} coursesData={coursesObj} />} />
                                <Route path="/?:searchParams" element={<Courses  searchParams={paramsObj} coursesData={coursesObj} />} />
                                <Route path="/wow" element={(<p>Hello</p>)} />
                              </Routes>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default App;
