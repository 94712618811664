const domain = (window.location.origin === 'http://localhost:3000') ? 'https://aims-local.lndo.site' : 'https://www.aims.edu';
const filterNameKey = {
  'SISTermID': 'Term',
  'Online_Only': 'Online Only',
  'Allowed_Locations': 'Locations',
  'CourseNumber': 'Course Number',
  'SectionStatus': 'Class Status',
  'StartAfter': 'Class Starts After',
  'EndBy': 'Class Ends By',
  'IsMonday': 'Selected Day',
  'IsTuesday': 'Selected Day',
  'IsWednesday': 'Selected Day',
  'IsThursday': 'Selected Day',
  'IsFriday': 'Selected Day',
  'IsSaturday': 'Selected Day',
  'IsSunday': 'Selected Day',
  'StartDate': 'Classes Start After:',
  'EndDate': 'Classes Ends By:',
};
const filterValueKey = {
  'SISTermID': {
    '202520': 'Fall Semester 2024',
    '202540': 'Spring Semester 2025',
  },
  'Online_Only': {
    'true': 'Yes',
    'false': 'No',
  },
  'Allowed_Locations': {
    'Greeley_Campus': 'Greeley Campus',
    'Flight_Training_Center': 'Flight Training Center',
    'Early_College_Academy': 'Early College Academy',
    'Loveland_Campus': 'Loveland Campus',
    'Fort_Lupton_Campus': 'Fort Lupton Campus',
    'Windsor_Campus': 'Windsor Campus',
    'High School Campus': 'High School Campus',
  },
  'IsMonday': {
    'true': 'Monday',
  },
  'IsTuesday': {
    'true': 'Tuesday',
  },
  'IsWednesday': {
    'true': 'Wednesday',
  },
  'IsThursday': {
    'true': 'Thursday',
  },
  'IsFriday': {
    'true': 'Friday',
  },
  'IsSaturday': {
    'true': 'Saturday',
  },
  'IsSunday': {
    'true': 'Sunday',
  },
};

export {domain, filterNameKey, filterValueKey};
