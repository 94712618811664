import TermSelect from "./filter_fields/Term";
import Location from "./filter_fields/Location";
import CourseNumber from "./filter_fields/CourseNumber";
import Title from "./filter_fields/Title";
import StartDate from "./filter_fields/StartDate";
import EndDate from "./filter_fields/EndDate";
import StartTime from "./filter_fields/StartTime";
import EndTime from "./filter_fields/EndTime";
import DaysofWeek from "./filter_fields/DaysCheckBox";
import Instructor from "./filter_fields/Instructor";
import OnlineOnly from "./filter_fields/OnlineOnly";
import OpenClasses from "./filter_fields/OpenClasses";
import Subject from "./filter_fields/Subject";
import "./Filters.css";

function Filters(props) {

  return (
      <>
          <hr />
          <div className="l-section l-section--four-col">
            <div className="l-section__inner">
              <div className="l-section__grid">
                <div className="l-section__col">
                  <TermSelect searchParams={props.searchParams} coursesObj={props.coursesObj} setCoursesObj={props.setCoursesObj} />
                  <Title searchParams={props.searchParams} coursesObj={props.coursesObj} setCoursesObj={props.setCoursesObj} />
                  <CourseNumber searchParams={props.searchParams} coursesObj={props.coursesObj} setCoursesObj={props.setCoursesObj} />
                  <Location searchParams={props.searchParams} coursesObj={props.coursesObj} setCoursesObj={props.setCoursesObj }/>
                </div>
                <div className="l-section__col">
                  <Instructor searchParams={props.searchParams} coursesObj={props.coursesObj} setCoursesObj={props.setCoursesObj} />
                  <OnlineOnly searchParams={props.searchParams} coursesObj={props.coursesObj} setCoursesObj={props.setCoursesObj} />
                  <OpenClasses searchParams={props.searchParams} coursesObj={props.coursesObj} setCoursesObj={props.setCoursesObj} />
                </div>
                <div className="l-section__col">
                  <Subject searchParams={props.searchParams} coursesObj={props.coursesObj} setCoursesObj={props.setCoursesObj} />
                </div>
                <div className="l-section__col">
                  <StartTime searchParams={props.searchParams} coursesObj={props.coursesObj} setCoursesObj={props.setCoursesObj} />
                  <EndTime searchParams={props.searchParams} coursesObj={props.coursesObj} setCoursesObj={props.setCoursesObj} />
                  <StartDate searchParams={props.searchParams} coursesObj={props.coursesObj} setCoursesObj={props.setCoursesObj} />
                  <EndDate searchParams={props.searchParams} coursesObj={props.coursesObj} setCoursesObj={props.setCoursesObj} />
                  <DaysofWeek searchParams={props.searchParams} coursesObj={props.coursesObj} setCoursesObj={props.setCoursesObj} />
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <a href="/" id="resetBtn">Reset</a>
          <br></br>
          <hr />
      </>
  );
}

export default Filters;
