import { useState, useEffect } from "react";

function EndTime(props) {

  const [endTime, setEndTime] = useState();
  const [linkedEndTime] = useState(props.searchParams.EndBy);
  const name = "EndBy";

  function handleStateChange (e) {
    let coursesObj = props.coursesObj;
    props.setCoursesObj({
      ...coursesObj,
      EndBy: e.target.value,
      default: false,
    });
    setEndTime(e.target.value);
  }

  function passSearchParams (e) {
    let coursesObj = props.coursesObj;
    props.setCoursesObj({
      ...coursesObj,
      EndBy: linkedEndTime
    });
    if (props.searchParams.EndBy) {
      setEndTime(linkedEndTime);
    }
  }

  useEffect(() => {
    passSearchParams();
  }, []);

  return (
      <>
        <label htmlFor="endTime" className="majorLabel">Time the Class Ends By:</label>
        <input
            type="time"
            id="endTime"
            name={name}
            value={endTime}
            onChange={handleStateChange}
            className="selectBox dayTimeBox"
        />
      </>
  );
}

export default EndTime;
