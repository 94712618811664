import './UserSelectedFilters.css';
import {filterNameKey, filterValueKey} from "../../constants/Global";

function UserSelectedFilters(props){

    function sanitizeTime (time) {
        if(parseInt(time.split(':')[0]) > 12){
            let hour = parseInt(time) - 12;
            let minute = time.split(':')[1];
            return time = hour + ':' + minute + ' PM';
        }
        else{
            if(time.charAt(0) === '0'){
                return time = time.substring(1) + ' AM';
            }
            else{
                return time = time + ' AM';
            }
        }
    }

    return(
        <>
            <h2>Search Results: </h2>
            <div className="c-results-filter__container">
                {Object.entries(props.coursesData).map(([key, value]) => {
                    if(value && key !== 'default'){
                        let name = (filterNameKey.hasOwnProperty(key)) ? filterNameKey[key] : key;
                        let filter_value = value;
                        if(filterValueKey.hasOwnProperty(key)){
                            if(filterValueKey[key].hasOwnProperty(value)){
                                filter_value = filterValueKey[key][value];
                            }
                        }
                        if(name === 'Instructor' || name === 'Subject'){
                            let temp_val = filter_value.split('-');
                            console.log(temp_val);
                            if(temp_val.length === 1){
                                // let elm_id = value.toString();
                            }
                            filter_value = temp_val[1];
                        }
                        if(name === 'Class Starts After' || name === 'Class Ends By'){
                            filter_value = sanitizeTime(filter_value);
                        }
                        return [
                            <>
                                <div className="c-results-filter__object-container">
                                    <p className="c-results-filter-display__filter-title">{name}</p>
                                    <ul className="c-results-filter-display__filters">
                                        <li className="u-tag">{filter_value}</li>
                                    </ul>
                                </div>
                            </>
                        ];
                    } else {
                        return null;
                    }
                })}
            </div>
        </>
    );
}

export default UserSelectedFilters;
