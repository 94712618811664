import { useState, useEffect } from "react";

function OnlineOnly(props) {

  const [linkedOnline] = useState(props.searchParams.Online_Only);
  const name = "Online_Only";

  function handleStateChange (e) {
    let coursesObj = props.coursesObj;
    props.setCoursesObj({
      ...coursesObj,
      Online_Only: e.target.value,
      default: false,
    });
  }

  function passSearchParams (e) {
    let coursesObj = props.coursesObj;
    props.setCoursesObj({
      ...coursesObj,
      Online_Only: linkedOnline
    });
  }

  useEffect(() => {
    passSearchParams();
  }, []);

  return (
    <>
      <span className="tooltip">
        <img className="icon" alt="Calendar Icon" src="https://www.aims.edu/themes/custom/aims/images/icons/question-mark.png"/>
        <span className="tooltiptext">If selecting Online Only, a Location can not also be selected.</span>
      </span>
      <label htmlFor="Online_Only" className="majorLabel">Online Only:</label>
      {linkedOnline !== '' && linkedOnline === 'true'
        ?
        <>
          <input type="radio" id="yesOnline" name={name} value="true" className="radio" checked onChange={handleStateChange}/>
          <label for="yesOnline">Yes</label>
          <input type="radio" id="noOnline" name={name} value="false" className="radio noOption" onChange={handleStateChange}/>
          <label for="noOnline">No</label>
        </>
        :
        null
      }
      {linkedOnline !== '' && linkedOnline === 'false'
        ?
        <>
          <input type="radio" id="yesOnline" name={name} value="true" className="radio" onChange={handleStateChange}/>
          <label for="yesOnline">Yes</label>
          <input type="radio" id="noOnline" name={name} value="false" className="radio noOption" checked onChange={handleStateChange}/>
          <label for="noOnline">No</label>
        </>
        :
        null
      }
      {linkedOnline === ''
        ?
        <>
          <input type="radio" id="yesOnline" name={name} value="true" className="radio" onChange={handleStateChange}/>
          <label htmlFor="yesOnline">Yes</label>
          <input type="radio" id="noOnline" name={name} value="false" className="radio noOption" onChange={handleStateChange}/>
          <label htmlFor="noOnline">No</label>
        </>
        :
        null
      }
    </>
  );
}

export default OnlineOnly;
