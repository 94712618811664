import { useState, useEffect } from "react";

function TermSelect(props) {

  const [defaultValue, setDefaultValue] = useState('202540');
  const [linkedTerm] = useState(props.searchParams.SISTermID);

  function handleStateChange (e) {
    let coursesObj = props.coursesObj;
    props.setCoursesObj({
      ...coursesObj,
      SISTermID: e.target.value,
      default: false,
    });
    setDefaultValue(e.target.value);
  }

  function passSearchParams (e) {
    let coursesObj = props.coursesObj;
    props.setCoursesObj({
      ...coursesObj,
      SISTermID: linkedTerm
    });
    if (props.searchParams.SISTermID) {
      setDefaultValue(linkedTerm);
    }
  }

  useEffect(() => {
    passSearchParams();
  }, []);

  return (
      <>
        {defaultValue !== '' && defaultValue === '202520'
            ?
            <>
              <label htmlFor="Term Selection" className="majorLabel">Term:</label>
              <input type="radio" id="Fall Semester 2024" name="SISTermID" value="202520" className="radio" checked onChange={handleStateChange}/>
              <label htmlFor="Fall Semester 2024">Fall Semester 2024</label>
              <br></br>
              <input type="radio" id="Spring Semester 2025" name="SISTermID" value="202540" className="radio" onChange={handleStateChange}/>
              <label htmlFor="Spring Semester 2025">Spring Semester 2025</label>
            </>
            :
            null
        }
        {defaultValue !== '' && defaultValue === '202540'
            ?
            <>
              <label htmlFor="Term Selection" className="majorLabel">Term:</label>
              <input type="radio" id="Fall Semester 2024" name="SISTermID" value="202520" className="radio" onChange={handleStateChange}/>
              <label htmlFor="Fall Semester 2024">Fall Semester 2024</label>
              <br></br>
              <input type="radio" id="Spring Semester 2025" name="SISTermID" value="202540" className="radio" checked onChange={handleStateChange}/>
              <label htmlFor="Spring Semester 2025">Spring Semester 2025</label>
            </>
            :
            null
        }
      </>
  );
}

export default TermSelect;
