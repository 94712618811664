import { useState, useEffect } from "react";

function Title(props) {

  const name = "Title";
  const [title, setTitle] = useState('');
  const [linkedTitle] = useState(props.searchParams.Title);

  // const handleStateChange = (e) => {
  //   setTitle(e.target.value);
  //   // Updating the 'age' property in the person object
  //   props.setCoursesObj((prevValues) => ({
  //     ...prevValues, // Spread the previous object to keep other properties
  //     Title: e.target.value,       // Update the specific key (age) to a new value
  //     default: false
  //   }));
  // };

  function handleStateChange (e) {
    let coursesObj = props.coursesObj;
    setTitle(e.target.value);
    props.setCoursesObj({
      ...coursesObj,
      Title: e.target.value,
      default: false,
    });
  }

  function passSearchParams (e) {
    let coursesObj = props.coursesObj;
    props.setCoursesObj({
      ...coursesObj,
      Title: linkedTitle
    });
    if (props.searchParams.Title) {
      setTitle(linkedTitle);
    }
  }

  useEffect(() => {
    passSearchParams();
  }, []);

  return (
    <>
      <label htmlFor="Title" className="majorLabel">Title:</label>
      <input
          type="input"
          id="Title"
          name={name}
          value={title}
          onChange={handleStateChange}
          className="inputBox"
      />
    </>
  );
}

export default Title;
