import "./Loader.css"
import {useEffect} from "react";

function Loader () {

  const anchorString = window.location.hash;

  useEffect(() => {
    handleScroll();
  }, []);

  function handleScroll(){
    if(anchorString === "#searchResults"){
      let results = document.getElementById('searchResults');
      if(results){
        results.scrollIntoView();
      }
    }
  }

  let timeLeft = 5;

  function tick(){
    if(timeLeft > 0){
      timeLeft--;
      setTimeout(tick, 1000);
    }
    else{
      let longLoad = document.getElementById('long-load');
      if(longLoad){
        longLoad.innerHTML = 'The results are still loading. Please do not reload the page.';
      }
    }
  }

  tick(5);

  return(
    <div id="loader">
      <img alt="Jumping Arty" src="https://media2.giphy.com/media/jp5BQzoMrZMAjxHIIv/200w.webp"></img>
      <h2>Loading Results...</h2>
      <p id="long-load"></p>
    </div>
  )
}

export default Loader;
