import { useEffect, useState } from "react";
import {domain} from "../../../constants/Global";

function Instructors(props) {

  const [loading, setLoading] = useState(false);
  const [instructors, setInstructors] = useState([]);
  const [linkedInstructor] = useState(props.searchParams.Instructor);
  const name = "Instructor";

  function loadInstructors() {
    setLoading(true);
    fetch(
      domain + "/api-proxy/workday?_api_proxy_uri=INT172_Aims_Public_Schedule_Instructors%3Fformat%3Djson",
        {
          method: "GET",
        }
    )
        .then((response) => response.json())
        .then((data) => {
          setInstructors(data.Report_Entry);
          setLoading(false);
        })
        .catch((error) => console.log(error));
  }

  function santizeArray(instructors){
    let allInstructors = [];
    for(let instructor of instructors){
      let nameAndID = instructor["Employee_ID-Name"];
      let employeeInfoArray = nameAndID.split('-');
      let employeeID = employeeInfoArray[0];
      let employeeName = employeeInfoArray[1];
      let employeeFullName = employeeName.split(' ');
      let employeeLastName = employeeFullName[employeeFullName.length - 1];
      employeeFullName.pop();
      let employeeFirstAndMiddle = employeeFullName.join(' ');
      //Writing case for specific professor
      if(employeeID === 'a57f720cac93014d0dbefb439a02d3d7'){
        let rewriteName = employeeName.split(' ');
        employeeFirstAndMiddle = rewriteName[0];
        employeeLastName = rewriteName[1] + ' ' + rewriteName[2];
      }
      let employee = {
        'employeeName': employeeName,
        'employeeID': employeeID,
        'employeeFirstAndMiddle': employeeFirstAndMiddle,
        'employeeLastName': employeeLastName
      };
      allInstructors.push(employee);
    }
    return allInstructors.sort((a, b) => a.employeeLastName.localeCompare(b.employeeLastName));
  }

  function handleStateChange (e) {
    let coursesObj = props.coursesObj;
    props.setCoursesObj({
      ...coursesObj,
      Instructor: e.target.value,
      default: false,
    });
  }

  function passSearchParams () {
    let coursesObj = props.coursesObj;
    props.setCoursesObj({
      ...coursesObj,
      Instructor: linkedInstructor
    });
  }

  // useEffect(() => {loadInstructors()}, []);
  useEffect(() => {loadInstructors();passSearchParams();}, []);
  let allInstructors = santizeArray(instructors);

  return (
      <>
        <label htmlFor="instructors" className="majorLabel">Instructor:</label>
        <select name={name} id="instructors" size="5" className="selectBox" onChange={handleStateChange}>
          {loading ? (
                <option>Loading List...</option>
              ) : (
                  allInstructors.map((instructor) => {
                    if(instructor.employeeID !== ''){
                      if(instructor.employeeID !== linkedInstructor){
                        return [
                          <>
                            <option id={instructor.employeeID} value={instructor.employeeID + '-' + instructor.employeeName}>{instructor.employeeLastName}, {instructor.employeeFirstAndMiddle}</option>
                          </>
                        ]
                      }
                      else{
                        return [
                          <>
                            <option id={instructor.employeeID} value={instructor.employeeID + '-' + instructor.employeeName} selected='true'>{instructor.employeeLastName}, {instructor.employeeFirstAndMiddle}</option>
                          </>
                        ]
                      }
                    } else {
                      return null;
                    }
                })
          )}
        </select>
      </>
  )
}



export default Instructors;
