import { useState, useEffect } from "react";

function EndDate(props) {

  const [endDate, setEndDate] = useState();
  const [linkedEndDate] = useState(props.searchParams.EndDate);
  const name = "EndDate";

  function handleStateChange (e) {
  let coursesObj = props.coursesObj;
    props.setCoursesObj({
      ...coursesObj,
      EndDate: e.target.value,
      default: false,
    });
    setEndDate(e.target.value);
  }

  function passSearchParams (e) {
    let coursesObj = props.coursesObj;
    props.setCoursesObj({
      ...coursesObj,
      EndDate: linkedEndDate
    });
    if (props.searchParams.EndDate) {
      setEndDate(linkedEndDate);
    }
  }

  useEffect(() => {
    passSearchParams();
  }, []);

  return (
      <>
        <label htmlFor="endDate" className="majorLabel">Date the Class Ends By:</label>
        <input
            type="date"
            id="endDate"
            name={name}
            value={endDate}
            onChange={handleStateChange}
            className="selectBox dayTimeBox"
        />
      </>
  );
}

export default EndDate;
