import {useRef} from 'react';

function CourseDetails({sorted, value}){

    const ref = useRef(null);

    function showDetails(e){
        const el = ref.current;

        //Open more details
        if (el.className === "show-more-details-true"){
            el.className = "show-more-details-false";
        }
        else{
            el.className = "show-more-details-true";
        }

        //Change arrow direction
        if (e.target.className === "more-details-closed"){
            e.target.className = "more-details-open";
            e.target.ariaExpanded = "true";
        }
        else{
            e.target.className = "more-details-closed";
            e.target.ariaExpanded = "false";
        }
    }

    return(
        <>
            <div className="course">
                <div className="course-card__header">
                    <p className="course-card__header__sisid">{sorted[value].display_description} Section: </p>
                    <p className="course-card__header__section">{sorted[value].Section_Listings_group[0].Section_Number}</p>
                    <p className="course-card__header__credits">Credits: <span>{sorted[value].Credits}</span></p>
                    {sorted[value].campus ? <><p className="course-card__header__campus">{sorted[value].campus}</p></> : null}
                    <p className="course-card__header__instructor">Instructor:
                      {sorted[value].Primary_Instructor_Email
                        ?
                        <>
                          &nbsp;<a href={'mailto:' + sorted[value].Primary_Instructor_Email}>
                            <span>{sorted[value].Primary_Instructor_Name}</span>
                          </a>
                        </>
                        :
                        null
                      }
                    </p>
                    <button className="more-details-closed" onClick={showDetails} id={sorted[value].SISID} data-section-id={'moredetails-' + sorted[value].SISID} aria-expanded="false">
                      <span className="arrow"></span>
                      <div className="visually-hidden">More Information</div>
                    </button>
                    <p className={sorted[value].Course_Status === 'Open' ? 'course-card__header__section_status' : 'course-card__header__section_status status_closed'} >{sorted[value].Course_Status}</p>
                    <p className="course-card__header__available">Enrolled: <span>{sorted[value].Enrollment_Capacity}</span></p>
                </div>
                <div className="course-card__footer">
                    <p className="course-card__footer__class-days">
                        <span>
                          <img className="icon" alt="Clock Icon" src="https://www.aims.edu/themes/custom/aims/images/icons/clock-blue.svg" />
                        </span>
                        <span className={sorted[value].Monday === '1' ? 'class-scheduled' : 'class-not-scheduled'}>M</span>
                        <span className={sorted[value].Tuesday === '1' ? 'class-scheduled' : 'class-not-scheduled'}>T</span>
                        <span className={sorted[value].Wednesday === '1' ? 'class-scheduled' : 'class-not-scheduled'}>W</span>
                        <span className={sorted[value].Thursday === '1' ? 'class-scheduled' : 'class-not-scheduled'}>R</span>
                        <span className={sorted[value].Friday === '1' ? 'class-scheduled' : 'class-not-scheduled'}>F</span>
                        <span className={sorted[value].Saturday === '1' ? 'class-scheduled' : 'class-not-scheduled'}>S</span>
                        <span className={sorted[value].Sunday === '1' ? 'class-scheduled' : 'class-not-scheduled'}>U</span>
                    </p>
                    {sorted[value].meeting_time ? <p className="course-card__footer__meeting-times"> {sorted[value].meeting_time}</p> : null}
                    <p className="course-card__footer__start-to-end">
                      <span>
                        <img className="icon" alt="Calendar Icon" src="https://www.aims.edu/themes/custom/aims/images/icons/calendar-blue.png" />
                      </span>
                      {sorted[value].Start_End_Date}
                    </p>
                    <p className="course-card__footer__location">
                      <span>
                        <img className="icon" alt="Map marker Icon" src="https://www.aims.edu/themes/custom/aims/images/icons/map-marker-blue.svg" />
                      </span>
                      {sorted[value].building_location} {sorted[value].room_location}
                    </p>
                </div>
                <div id={'moredetails-' + sorted[value].SISID} aria-labelledby={sorted[value].SISID} className="show-more-details-false" ref={ref}>
                    <div className="course-card__show-more__heading">
                        <img className="icon icon-large" alt="Information Icon" src="https://www.aims.edu/themes/custom/aims/images/icons/information-red.png" />
                        <h5>Additional Information</h5>
                    </div>
                    <div className="course-card__show-more__section-information">
                        <div className="section-information__col-1">
                          <p><strong>Delivery Method:</strong> {sorted[value].Delivery_Mode}</p>
                          <p><strong>Special Topic:</strong> {sorted[value].Special_Topic}</p>
                          <p><strong>Course Prerequisites:</strong> {sorted[value].Course_Prerequisites}</p>
                          {/*Co Req is empty but Cluster has data*/}
                          {sorted[value].co_requisite_courses === 'None' && sorted[value].cluster_course_sections !== 'None'
                            ? <>
                                <p><strong>Section Corequisites:</strong> {sorted[value].cluster_course_sections}</p>
                              </>
                            : null
                          }
                          {/*Both Fields have data*/}
                          {sorted[value].co_requisite_courses !== 'None' && sorted[value].cluster_course_sections !== 'None'
                            ? <>
                                <p><strong>Section Corequisites:</strong> {sorted[value].co_requisite_courses}</p>
                              </>
                            : null
                          }
                          {/*Co Req has data, but cluster doesn't*/}
                          {sorted[value].co_requisite_courses !== 'None' && sorted[value].cluster_course_sections === 'None'
                            ? <>
                              <p><strong>Section Corequisites:</strong> {sorted[value].co_requisite_courses}</p>
                            </>
                            : null
                          }
                          {/*Both Co Req and Cluster don't have data*/}
                          {sorted[value].co_requisite_courses === 'None' && sorted[value].cluster_course_sections === 'None'
                            ? <>
                              <p><strong>Section Corequisites:</strong> {sorted[value].co_requisite_courses}</p>
                            </>
                            : null
                          }
                        </div>
                        <div className="section-information__col-2">
                          {sorted[value].Public_Notes ?
                            <>
                              <p><strong>Public Notes:</strong> {sorted[value].public_notes}</p>
                            </> :
                            ''}
                        </div>
                    </div>
                    <hr></hr>
                    {sorted[value].multiple_meeting_time ?
                      <>
                        <div className="course-card__show-more__heading">
                          <img className="icon icon-large" alt="Calendar Icon" src="https://www.aims.edu/themes/custom/aims/images/icons/clock.svg" />
                          <h5>Meeting Times</h5>
                        </div>
                        <p className="course-card__show-more__term">
                          {sorted[value].multiple_meeting_time.map((times) => {
                            return [
                              <>
                                <div>{times}</div>
                              </>
                            ]
                          })}
                        </p>
                        <hr></hr>
                      </>
                      : null}
                    <div className="course-card__show-more__heading">
                        <img className="icon icon-large" alt="Calendar Icon" src="https://www.aims.edu/themes/custom/aims/images/icons/calendar-red.png" />
                        <h5>Important Dates</h5>
                    </div>
                    <p className="course-card__show-more__term">
                      <strong>Term:</strong> {sorted[value].Term}
                    </p>
                    <div className="course-card__show-more__important-dates">
                        <div className="important-dates__col-1">
                            <p><strong>{sorted[value].last_day_to_add}</strong>
                                <br></br>
                                Last day to add classes
                            </p>
                        </div>
                        <div className="important-dates__col-2">
                          <p><strong>{sorted[value].last_day_to_drop}</strong>
                            <br></br>
                            Last day to drop
                          </p>
                        </div>
                        <div className="important-dates__col-3">
                          <p><strong>{sorted[value].last_day_to_withdraw}</strong>
                            <br></br>
                            Last day to Withdraw
                          </p>
                        </div>
                    </div>
                  <hr></hr>
                  <div className="course-card__show-more__heading">
                    <img className="icon icon-large" alt="Calendar Icon" src="https://www.aims.edu/themes/custom/aims/images/icons/textbook.svg" />
                    <h5>Texbooks</h5>
                  </div>
                  <p className="course-card__show-more__textbook">
                    Textbooks, supplies, and clothing can be ordered by visiting the&nbsp;<a href="https://aims.bncollege.com/course-material/course-finder" target="_blank" rel="noreferrer">Aims Community College Bookstore</a> website.
                    Please make a note of the course location, course number (for example, {sorted[value].Course_Number}) and course section (for example, {sorted[value].Section_Listings_group[0].Section_Number})
                  </p>
                  {sorted[value].Delivery_Mode === 'Online' && 
                    <>
                      <hr />
                      <div className="course-card__show-more__heading">
                        <img className="icon icon-large" alt="Question Icon" src='https://www.aims.edu/themes/custom/aims/images/icons/question-mark.png' />
                        <h5>Online Classes Help</h5>
                      </div>
                      <p className="course-card__show-more__online-help">
                        <a href="https://www.aims.edu/myaims-student/how-take-classes-online" target="_blank" rel="noreferrer">Learn more about taking online classes</a>
                      </p>
                    </>
                  }
                  {sorted[value].display_crosslist_seating ?
                    <>
                      <hr></hr>
                      <div className="course-card__show-more__heading">
                        <img className="icon icon-large" alt="Copy Icon" src="https://www.aims.edu/themes/custom/aims/images/icons/copy-red.png" />
                        <h5>These Classes Meet Together</h5>
                      </div>
                    </>
                    : null}
                  {sorted[value].crosslist_seating ?
                    sorted[value].crosslist_seating.map((course) => {
                        return [
                        <p className="crosslist-seating">{course}</p>
                        ]
                      })
                  : null}
                </div>
            </div>
        </>
    );
}
export default CourseDetails;
