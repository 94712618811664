import { useState, useEffect } from "react";

function StartDate(props) {

  const [startDate, setStartDate] = useState();
  const [linkedStartDate] = useState(props.searchParams.StartDate);
  const name = "StartDate";

  function handleStateChange (e) {
    let coursesObj = props.coursesObj;
    props.setCoursesObj({
      ...coursesObj,
      StartDate: e.target.value,
      default: false,
    });
    setStartDate(e.target.value);
  }

  function passSearchParams (e) {
    let coursesObj = props.coursesObj;
    props.setCoursesObj({
      ...coursesObj,
      StartDate: linkedStartDate
    });
    if (props.searchParams.StartDate) {
      setStartDate(linkedStartDate);
    }
  }

  useEffect(() => {
    passSearchParams();
  }, []);

  return (
      <>
        <label htmlFor="startDate" className="majorLabel">Date the Class Starts After:</label>
        <input
            type="date"
            id="startDate"
            name={name}
            value={startDate}
            onChange={handleStateChange}
            className="selectBox dayTimeBox"
        />
      </>
  );
}

export default StartDate;
