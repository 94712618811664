import { useEffect, useState } from "react";

function CourseNumber(props) {

  const [course, setCourse] = useState('');
  const [linkedCourse] = useState(props.searchParams.CourseNumber);
  const name = "CourseNumber";

  function handleStateChange (e) {
    setCourse(e.target.value);
    let coursesObj = props.coursesObj;
    props.setCoursesObj({
      ...coursesObj,
      CourseNumber: e.target.value,
      default: false,
    });
  }

  function passSearchParams () {
    let coursesObj = props.coursesObj;
    props.setCoursesObj({
      ...coursesObj,
      CourseNumber: linkedCourse
    });
    if (props.searchParams.CourseNumber) {
      setCourse(linkedCourse);
    }
  }

  useEffect(() => {passSearchParams()}, []);

  return (
      <>
          <label htmlFor="courseNum" className="majorLabel">Course #:</label><span className="help-text">No spaces allowed</span>
          <input
              type="input"
              id="courseNum"
              name={name}
              value={course}
              onChange={handleStateChange}
              className="inputBox"
              placeholder={linkedCourse}
          ></input>
      </>
  );
}

export default CourseNumber;
